import { OperatorBranch } from './operator_branch.model';
var Operator = /** @class */ (function () {
    function Operator(init) {
        this.operator_branches = [];
        this.activeBranch = null;
        Object.assign(this, init);
        if (this.operator_branches.length > 0) {
            this.activeBranch = new OperatorBranch(this.operator_branches[0]);
        }
    }
    Object.defineProperty(Operator.prototype, "permissions", {
        get: function () {
            return this.activeBranch && this.activeBranch.permission_group && this.activeBranch.permission_group.permissions ? this.activeBranch.permission_group.permissions : null;
        },
        enumerable: true,
        configurable: true
    });
    Operator.prototype.canAccessTo = function (key) {
        var variables = {
            exchange: this.role === 'admin' ? true : (this.permissions && !!this.permissions.exchange.can_view),
            operate: this.role === 'admin' ? true : (this.permissions && !!this.permissions.operate.can_view),
            clients: this.role === 'admin' ? true : (this.permissions && !!this.permissions.clients.can_view),
            history: this.role === 'admin' ? true : (this.permissions && !!this.permissions.history.can_view),
            history_provider: this.role === 'admin' ? true : (this.permissions && !!this.permissions.history_provider.can_view),
            products: this.role === 'admin' ? true : (this.permissions && !!this.permissions.products.can_view),
            products_shop: this.role === 'admin' ? true : (this.permissions && !!this.permissions.products_shop.can_view),
            statistics: this.role === 'admin' ? true : (this.permissions && !!this.permissions.statistics.can_view),
            validate: this.role === 'admin' ? true : (this.permissions && !!this.permissions.validate.can_view),
            integrations: this.role === 'admin' ? true : (this.permissions && !!this.permissions.integrations.can_view),
            levels: this.role === 'admin' ? true : (this.permissions && !!this.permissions.levels.can_view),
            surveys: this.role === 'admin' ? true : (this.permissions && !!this.permissions.surveys.can_view),
        };
        return variables[key];
    };
    Object.defineProperty(Operator.prototype, "isAdmin", {
        get: function () {
            return this.role === 'admin';
        },
        enumerable: true,
        configurable: true
    });
    return Operator;
}());
export { Operator };
